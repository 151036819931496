export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const SET_CONFIG = "SET_CONFIG";
export const LOADING_USER = "LOADING_USER";
export const CLEAR_DATA = "CLEAR_DATA";
export const STOP_LOADING_USER = "STOP_LOADING_USER";
export const SET_SEARCH_DATA = "SET_SEARCH_DATA";
export const SET_NETBOX_SITE_DEVICES = "SET_NETBOX_SITE_DEVICES";
export const SET_UNIFI_SITE = "SET_UNIFI_SITE";
export const SET_UNIFI_DATA = "SET_UNIFI_DATA";
export const SET_MT_DATA = "SET_MT_DATA";
export const SET_ORGANISATION = "SET_ORGANISATION";
export const SET_USER_ERROR = "SET_USER_ERROR";
export const SET_UNIFI_SWITCH_DATA = "SET_UNIFI_SWITCH_DATA";
export const SET_LOADING = "SET_LOADING";
export const SET_MAIN_PAGE = "SET_MAIN_PAGE";
export const SET_MIKROTIK_STATE = "SET_MIKROTIK_STATE";
export const SET_UNIFIAP_STATE = "SET_UNIFIAP_STATE";
export const SET_UNIFISWITCH_STATE = "SET_UNIFISWITCH_STATE";
export const DELETE_MIKROTIK_STATE = "DELETE_MIKROTIK_STATE";
export const SET_SIDEBAR = "SET_SIDEBAR";
export const SET_WIFI_SUM = "SET_WIFI_SUM";
export const SET_LAST_AP_TXRX = "SET_LAST_AP_TXRX";
export const SET_LAST_USER_SUM = "SET_LAST_USER_SUM";
export const FIRST_SCHOOLS_STATISTICS_DROPDOWN_SCHOOLS = "FIRST_SCHOOLS_STATISTICS_DROPDOWN_SCHOOLS";
export const SECOND_SCHOOLS_STATISTICS_DROPDOWN_REGIONS = "SECOND_SCHOOLS_STATISTICS_DROPDOWN_REGIONS";
export const SCHOOLS_STATISTICS_DATA = "SCHOOLS_STATISTICS_DATA";
export const SCHOOLS_STATISTICS_TABLE_DATA = "SCHOOLS_STATISTICS_TABLE_DATA";
export const ORGANISATIONS_DROPDOWN = "ORGANISATIONS_DROPDOWN";
export const WAREHOUSE_DEVICES_DROPDOWN = "WAREHOUSE_DEVICES_DROPDOWN";
export const UNIFI_URL_DROPDOWN = "UNIFI_URL_DROPDOWN";
export const SET_SITE_LOCATIONS = "SET_SITE_LOCATIONS";
export const SET_CURRENT_LOCATION_ID = "SET_CURRENT_LOCATION_ID";
export const SET_SITE_CONTACTS = "SET_SITE_CONTACTS";
export const SET_ALL_LOCATIONS = "SET_ALL_LOCATIONS";