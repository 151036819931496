import React, { lazy, Suspense, useEffect } from "react";
import "./App.css";
import store from "./redux/store";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import axios from "axios";
import { logoutUser, checkUser } from "./redux/actions/userActions";
import Cookie from "js-cookie";
import Navigation from "./components/Navigation";
import ScrollTop from "./components/ScrollTop";
import AuthRoute from "./components/AuthRoute";
import { useDispatch, useSelector } from "react-redux";

const home = lazy(() => import("./pages/home"));
const devices = lazy(() => import("./pages/Devices"));
const login = lazy(() => import("./pages/login"));
const statistics = lazy(() => import("./pages/Statistics"));

let logout;

const App = () => {
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // REIKIA useEffect, parsigauti paprasto admino organizacijos info.
  // tuomet  device path galima pakeisti i /?site_id=aaa&location_id=bbb

  useEffect(() => {
    window.scrollTo(200, 100);
    const logOutTIme = localStorage.getItem("lou");
    const cookieToken = Cookie.get("tkn");
    //console.log(cookieToken);
    if (cookieToken) {
      axios.defaults.headers.common["Authorization"] = cookieToken;
      dispatch(checkUser());
    } else {
      dispatch(logoutUser());
    }
  }, []);

  const logOutIfTokenExp = () => {
    const logOutTIme = localStorage.getItem("lou");
    console.log("Loging out after " + logOutTIme / 1000 + "s");
    logout = setTimeout(() => {
      clearTimeout(logout);
      console.log("Token expired, loging out");
      store.dispatch(logoutUser());
    }, logOutTIme);
  };

  const getYear = () => {
    const date = new Date().getFullYear();
    return date;
  };

  const logoutUsers = () => {
    clearTimeout(logout);
    localStorage.removeItem("lou");
    this.props.logoutUser();
  };

  const closeConnectionModal = () => {
    this.setState({ connection: true });
  };

  return (
    <div className="main-window">
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        {userState.user && !userState.loading ? (
          <>
            <ScrollTop />
            <Navigation style={{ zIndex: 999 }} />

            {/* {authenticated ? (
            !loading &&
            user && ( */}
            {/* <Suspense fallback={""}>
              <Switch>
                <AuthRoute path="/devices/:id" component={devices} />
                <AuthRoute component={home} />
              </Switch>
            </Suspense> */}

            <Suspense fallback={""}>
              {userState.user.role === "admin" &&
              userState.user.organization ? (
                
                <Switch>
                  {/* <AuthRoute path="/:site_id/:location_id" component={devices} /> */}
                  {/* Testavimas */}
                  <AuthRoute exact path='/' component={devices} />
                  <AuthRoute path="/:site_id/:location_id" component={devices} />
                </Switch>
              ) : (
                <Switch>
                  {/* <AuthRoute path="/statistics/:id" component={statistics} /> */}
                  <AuthRoute path="/statistics" component={statistics} />
                  <AuthRoute path="/inventory/:site_id/:location_id" component={devices} />
                  <AuthRoute component={home} />
                </Switch>
              )}
            </Suspense>
          </>
        ) : (
          <Suspense fallback={""}>
            <Switch>
              <Route exact component={login} />
              {/* <div
                style={{
                  minHeight: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    //marginLeft: 25,
                    backgroundColor: "#f8d7da",
                    padding: 5,
                    color: "#721c24",
                    borderRadius: 8,
                    border: "1px solid #f5c6cb",
                    paddingLeft: 15,
                    paddingRight: 15,
                    width: 300,
                    position: "relative",
                    boxShadow: "0px 14px 20px -16px rgba(0,0,0,0.75)",
                  }}
                  className="anim-slide-up"
                >
                  Vykdomi atnaujinimo darbai (planuojama darbų pabaiga 2023-08-30 15:00). Atsiprašome už nesklandumus.
                </div>
              </div> */}
            </Switch>
          </Suspense>
        )}
      </Router>
    </div>
  );
};
export default App;
