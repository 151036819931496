import {
    SET_ORGANISATION,
    SET_MIKROTIK_STATE,
    SET_UNIFI_DATA,
    SET_MT_DATA,
    SET_UNIFI_SWITCH_DATA,
    SCHOOLS_STATISTICS_DATA,
} from "../types";
import axios from "axios";
import { toTimestamp } from "../../components/functions";
import moment from "moment";

function setDate(period){
    var date = [];
    if (period[0].value === "1d"){
        date = {
            dateFrom: moment().subtract(1, "days"),
            dateTo: moment(),
        }
    }
    if (period[0].value === "7d"){
        date = {
            dateFrom: moment().subtract(7, "days"),
            dateTo: moment(),
        }
    }
    if (period[0].value === "30d") {
        date = {
            dateFrom: moment().subtract(30, "days"),
            dateTo: moment(),
        }
    }
    if (period[0].value === "12m") {
        date = {
            dateFrom: moment().subtract(12, "month"),
            dateTo: moment(),
        }
    }
    return date;
}

async function getSchoolDevices(school, tree) {
    var data;
    if (tree){
        await axios
            .post("/api/getdeviceslist", { site_id: school.id, ap: true })
            .then((res) => {
                data = res.data.result.results;
            })
            .catch((err) => {
                console.log(err.response.data.error);
            });
    } else {
        await axios
            .post("/api/getdeviceslist", { site_id: school.value[0].id, ap: true })
            .then((res) => {
                data = res.data.result.results;
            })
            .catch((err) => {
                console.log(err.response.data.error);
            });
    }
        return data;
}

async function getTreeSchoolDevices(schoolID) {
    var data;
    await axios
        .post("/api/getdeviceslist", { site_id: schoolID, ap: true })
        .then((res) => {
            data = res.data.result.results;
        })
        .catch((err) => {
            console.log(err.response.data.error);
        });
        return data;
}

const getSchoolsMT = (schoolDevices) => {
    var schoolMT;
    if(Object.prototype.toString.call(schoolDevices) === '[object Array]'){
        for (var i = 0; i < schoolDevices.length; i++){
            if(schoolDevices[i].name.includes("MT")){
                schoolMT = schoolDevices[i];
            }
        }
    }
    return schoolMT;
}

async function getMTData (devices, ids, date, period) {
    var MTData;
    // , MTDataDevice;
    // await axios
    //     .post("/api/getdevicedata", {
    //         device: device,
    //     })
    //     .then((res) => {
    //         MTDataDevice = res.data;
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     });
    var dateFrom;
    var dateTo;
    if(date == "-"){
        dateFrom = "-";
        dateTo = "-";
    } else{
        dateFrom = toTimestamp(date.dateFrom);
        dateTo = toTimestamp(date.dateTo);
    }
    await axios
        .post("/api/getdeviceitemdata", {
            devices: devices,
            ids: ids,
            date: period,
            dateFrom: dateFrom,
            dateTo: dateTo,
        })
        .then((res) => {
            MTData = res.data;
        })
        .catch((err) => {
            console.log(err);
        });

    return MTData;
}

async function getUsersData(slug, date, period){
    var results;
    var dateFrom;
    var dateTo;
    if(date == "-"){
        dateFrom = "-";
        dateTo = "-";
    } else{
        dateFrom = toTimestamp(date.dateFrom);
        dateTo = toTimestamp(date.dateTo);
    }
    await axios
        .post("/api/getstatisticsusers", {
            slug: slug,
            date: period,
            dateFrom: dateFrom,
            dateTo: dateTo,
        })
        .then((res) => {
            results = res.data;
        })
        .catch((err) => {
            console.log(err);
        });

    return results;
}

function treeAPCountSum(schools){
    var ap_count = 0;
    for(var i = 0; i < schools.length; i++){
        ap_count += parseInt(schools[i].custom_fields.ap_sk);
    }
    return ap_count;
}

function calculateAverage(value){
    var sum = 0;
    var avg = 0;
    for(var i = 0; i < value.length; i++){
        sum += parseFloat(value[i].value);
    }
    avg = sum / value.length;
    return avg;
}

export async function tableData (schools, period) {
    var mtdata;
    var usersData;
    var data = [];

    var date = setDate(period);

    var mtArray = [];
    var slugsArray = [];
    var slugsKeys = [];
    var j = 0;
    for(var i = 0; i < schools.length; i++) {
        if(schools[i].value[0].schools == null){
            mtArray[i] = await getSchoolsMT(await getSchoolDevices(schools[i], false));
            slugsArray[i] = schools[i].value[0].slug;
            slugsKeys[j] = `Average users per ${schools[i].value[0].slug} per day`;
            j++;
            slugsKeys[j] = `Maximum users per ${schools[i].value[0].slug} per day`;
            j++;
        }
    }

    if(slugsKeys.length != 0 ){
        usersData = await getUsersData(slugsKeys, date, period[0].value);
    }

    var mtDevicesIPs = [];
    var mtDevicesIDs = [];
    for(var i = 0; i < mtArray.length; i ++){
        if(mtArray[i] != null){
            mtDevicesIPs[i] = mtArray[i].custom_fields["IP_adresas"];
            mtDevicesIDs[i] = mtArray[i].id;
        }
    }

    if(mtDevicesIPs.length != 0) {
        mtdata = await getMTData(mtDevicesIPs, mtDevicesIPs, date, period[0].value);
    }

    for(var i = 0; i < schools.length; i++){
        if(schools[i].value[0].schools == null){
            data[i] = {
                "Mokykla": schools[i].label,
                "Mokinių skaičius": schools[i].value[0].mok_sk,
                "Įdiegtas AP kiekis": schools[i].value[0].ap_sk,
                expandable: true,
            }
            for(var j = 0; j < usersData.data2.length; j++){
                if(usersData.data2[j].slug.includes(schools[i].value[0].slug) && usersData.data2[j].slug.includes("Average")){
                    //data[i]["Vidutinis prisijungusių įrenginių sk."] = usersData.data2[j].value;
                    data[i]["Vid. prisijungusių įrenginių sk."] = usersData.data2[j].value;
                }
                if(usersData.data2[j].slug.includes(schools[i].value[0].slug) && usersData.data2[j].slug.includes("Maximum")){
                    //data[i]["Maksimalus prisijungusių įrenginių sk."] = usersData.data2[j].value;
                    data[i]["Maks. prisijungusių įrenginių sk."] = usersData.data2[j].value;
                }
            }

            for(var j = 0; j < mtdata.data2.length; j++){
                mtdata.data2[j].schoolsName = mtdata.data2[j].schoolsName.replace("rajonas", "r.");
                schools[i].value[0].name = schools[i].value[0].name.replace("rajonas", "r.");
                mtdata.data2[j].schoolsName = mtdata.data2[j].schoolsName.replace("rajono", "r.");
                schools[i].value[0].name = schools[i].value[0].name.replace("rajono", "r.");
                if(mtdata.data2[j].schoolsName.includes(schools[i].value[0].name) && mtdata.data2[j].name == "Wireless Received avg (d.d)"){
                    //data[i]["Vidutinis RX srautas (parsiuntimas) (Mbps)"] = mtdata.data2[j].value;
                    data[i]["Vid. parsiuntimo srautas (RX) (Mbps)"] = mtdata.data2[j].value;
                }
                if(mtdata.data2[j].schoolsName.includes(schools[i].value[0].name) && mtdata.data2[j].name == "Wireless Received max (d.d)"){
                    //data[i]["Maksimalus RX srautas (parsiuntimas) (Mbps)"] = mtdata.data2[j].value;
                    data[i]["Maks. parsiuntimo srautas (RX) (Mbps)"] = mtdata.data2[j].value;
                }
                if(mtdata.data2[j].schoolsName.includes(schools[i].value[0].name) && mtdata.data2[j].name == "Wireless Transmited avg (d.d.)"){
                    //data[i]["Vidutinis RX srautas (išsiuntimas) (Mbps)"] = mtdata.data2[j].value;
                    data[i]["Vid. išsiuntimo srautas (RX) (Mbps)"] = mtdata.data2[j].value;
                }
                if(mtdata.data2[j].schoolsName.includes(schools[i].value[0].name) && mtdata.data2[j].name == "Wireless Transmited max (d.d.)"){
                    //data[i]["Maksimalus RX srautas (išsiuntimas) (Mbps)"] = mtdata.data2[j].value;
                    data[i]["Maks. išsiuntimo srautas (RX) (Mbps)"] = mtdata.data2[j].value;
                }
            }
        } else {
            var ap_sk = treeAPCountSum(schools[i].value[0].schools);
            data[i] = {
                "Mokykla": schools[i].label,
                "Mokinių skaičius": schools[i].value[0].mok_sk,
                "Įdiegtas AP kiekis": ap_sk,
                // "Vidutinis prisijungusių įrenginių sk.": '-',
                // "Maksimalus prisijungusių įrenginių sk.": '-',
                // "Vidutinis RX srautas (parsiuntimas) (Mbps)": '-',
                // "Maksimalus RX srautas (parsiuntimas) (Mbps)": '-',
                // "Vidutinis RX srautas (išsiuntimas) (Mbps)": '-',
                // "Maksimalus RX srautas (išsiuntimas) (Mbps)": '-',
                "Vid. prisijungusių įrenginių sk.": '-',
                "Maks. prisijungusių įrenginių sk.": '-',
                "Vid. parsiuntimo srautas (RX) (Mbps)": "-",
                "Maks. parsiuntimo srautas (RX) (Mbps)": "-",
                "Vid. išsiuntimo srautas (RX) (Mbps)": "-",
                "Maks. išsiuntimo srautas (RX) (Mbps)": "-",
                schools: schools[i].value[0].schools,
                expandable: false,
            }
        }
    }

    // for(var i = 0; i < schools.length; i++) {
    //     if(schools[i].value[0].schools == null) {
    //         usersData = await getUsersData(schools[i].value[0].slug, date);
    //         mtdata = await getMTData(await getSchoolsMT(await getSchoolDevices(schools[i])), date);
    //         if(period[0].value == "1d"){
    //             data[i] = {
    //                 name: schools[i].label,
    //                 mok_sk: schools[i].value[0].mok_sk,
    //                 ap_count: schools[i].value[0].ap_sk,
    //                 vid_paros: (mtdata.data[0].lastvalue / 1000000).toFixed(2),
    //                 max_paros: (mtdata.data[1].lastvalue / 1000000).toFixed(2),
    //                 vid_users: usersData.dataAvgHist[0].value,
    //                 max_users: usersData.dataMaxHist[0].value,
    //             }
    //         } else{
    //             var itemDataOne = calculateAverage(mtdata.itemDataOne_);
    //             var itemDataTwo = calculateAverage(mtdata.itemDataTwo_);
    //             var vidUsersHist = calculateAverage(usersData.dataAvgHist).toFixed(0);
    //             var maxUsersHist = calculateAverage(usersData.dataMaxHist).toFixed(0);
    //             if(vidUsersHist == 'NaN'){
    //                 vidUsersHist = '-';
    //             } 
    //             if(maxUsersHist == 'NaN') {
    //                 maxUsersHist = '-';
    //             }
    //             data[i] = {
    //                 name: schools[i].label,
    //                 mok_sk: schools[i].value[0].mok_sk,
    //                 ap_count: schools[i].value[0].ap_sk,
    //                 vid_paros: (itemDataOne / 1000000).toFixed(2),
    //                 max_paros: (itemDataTwo / 1000000).toFixed(2),
    //                 vid_users: vidUsersHist,
    //                 max_users: maxUsersHist,
    //             }
    //         } 
    //     } else {
    //         var ap_sk = treeAPCountSum(schools[i].value[0].schools);
    //         data[i] = {
    //             name: schools[i].label,
    //             mok_sk: schools[i].value[0].mok_sk,
    //             ap_count: ap_sk,
    //             vid_paros: '-',
    //             max_paros: '-',
    //             vid_users: '-',
    //             max_users: '-',
    //             schools: schools[i].value[0].schools,
    //         }
    //     }
    // }
    return data;
}

export async function treeTableData (schools, period) {

    var mtdata;
    var usersData;
    var data = [];

    var date = setDate(period);

    var mtArray = [];
    var slugsArray = [];
    var slugsKeys = [];
    var j = 0;
    for(var i = 0; i < schools.length; i++){
        mtArray[i] = await getSchoolsMT(await getSchoolDevices(schools[i], true));
        slugsArray[i] = schools[i].slug;
        slugsKeys[j] = `Average users per ${schools[i].slug} per day`;
        j++;
        slugsKeys[j] = `Maximum users per ${schools[i].slug} per day`;
        j++;
    }

    usersData = await getUsersData(slugsKeys, date, period[0].value);

    var mtDevicesIPs = [];
    var mtDevicesIDs = [];
    for(var i = 0; i < mtArray.length; i++){
        mtDevicesIPs[i] = mtArray[i].custom_fields["IP_adresas"];
        mtDevicesIDs[i] = mtArray[i].id;
    }

    mtdata = await getMTData(mtDevicesIPs, mtDevicesIPs, date, period[0].value);

    for(var i = 0; i < schools.length; i++){
        data[i] = {
            "Mokykla": schools[i].name,
            "Įdiegtas AP kiekis": schools[i].custom_fields.ap_sk,
        }
        for(var j = 0; j < usersData.data2.length; j++){
            if(usersData.data2[j].slug.includes(schools[i].slug) && usersData.data2[j].slug.includes("Average")){
                // data[i]["Vidutinis prisijungusių įrenginių sk."] = usersData.data2[j].value;
                data[i]["Vid. prisijungusių įrenginių sk."] = usersData.data2[j].value;
            }
            if(usersData.data2[j].slug.includes(schools[i].slug) && usersData.data2[j].slug.includes("Maximum")){
                // data[i]["Maksimalus prisijungusių įrenginių sk."] = usersData.data2[j].value;
                data[i]["Maks. prisijungusių įrenginių sk."] = usersData.data2[j].value;
            }
        }

        for(var j = 0; j < mtdata.data2.length; j++){
            mtdata.data2[j].schoolsName = mtdata.data2[j].schoolsName.replace("rajonas", "r.");
            schools[i].name = schools[i].name.replace("rajonas", "r.");
            mtdata.data2[j].schoolsName = mtdata.data2[j].schoolsName.replace("rajono", "r.");
            schools[i].name = schools[i].name.replace("rajono", "r.");
            if(mtdata.data2[j].schoolsName.includes(schools[i].name) && mtdata.data2[j].name == "Wireless Received avg (d.d)"){
                //data[i]["Vidutinis RX srautas (parsiuntimas) (Mbps)"] = mtdata.data2[j].value;
                data[i]["Vid. parsiuntimo srautas (RX) (Mbps)"] = mtdata.data2[j].value;
            }
            if(mtdata.data2[j].schoolsName.includes(schools[i].name) && mtdata.data2[j].name == "Wireless Received max (d.d)"){
                // data[i]["Maksimalus RX srautas (parsiuntimas) (Mbps)"] = mtdata.data2[j].value;
                data[i]["Maks. parsiuntimo srautas (RX) (Mbps)"] = mtdata.data2[j].value;
            }
            if(mtdata.data2[j].schoolsName.includes(schools[i].name) && mtdata.data2[j].name == "Wireless Transmited avg (d.d.)"){
                // data[i]["Vidutinis RX srautas (išsiuntimas) (Mbps)"] = mtdata.data2[j].value;
                data[i]["Vid. išsiuntimo srautas (RX) (Mbps)"] = mtdata.data2[j].value;
            }
            if(mtdata.data2[j].schoolsName.includes(schools[i].name) && mtdata.data2[j].name == "Wireless Transmited max (d.d.)"){
                // data[i]["Maksimalus RX srautas (išsiuntimas) (Mbps)"] = mtdata.data2[j].value;
                data[i]["Maks. išsiuntimo srautas (RX) (Mbps)"] = mtdata.data2[j].value;
            }
        }
    }

    // var mtdata;
    // var usersData;
    // var data = [];
    // const date = setDate(period);

    // for(var i = 0; i < schools.length; i++){
    //     usersData = await getUsersData(schools[i].slug, date);
    //     mtdata = await getMTData(await getSchoolsMT(await getTreeSchoolDevices(schools[i].id)), date);
    //     if(period[0].value == "1d"){
    //         if((mtdata.data[0].lastvalue / 1000000).toFixed(2) != null && (mtdata.data[1].lastvalue / 1000000).toFixed(2) != null) {
    //             data[i] = {
    //                 name: schools[i].name,
    //                 mok_sk: '-',
    //                 ap_count: schools[i].custom_fields.ap_sk,
    //                 vid_paros: (mtdata.data[0].lastvalue / 1000000).toFixed(2),
    //                 max_paros: (mtdata.data[1].lastvalue / 1000000).toFixed(2),
    //                 vid_users: usersData.dataAvgHist[0].value,
    //                 max_users: usersData.dataMaxHist[0].value,
    //             }
    //         } else {
    //             data[i] = {
    //                 name: schools[i].name,
    //                 mok_sk: '-',
    //                 ap_count: schools[i].custom_fields.ap_sk,
    //                 vid_paros: '-',
    //                 max_paros: '-',
    //                 vid_users: usersData.dataAvgHist[0].value,
    //                 max_users: usersData.dataMaxHist[0].value,
    //             }
    //         }
    //     } else {
    //         var itemDataOne = calculateAverage(mtdata.itemDataOne_);
    //         var itemDataTwo = calculateAverage(mtdata.itemDataTwo_);
    //         var vidUsersHist = calculateAverage(usersData.dataAvgHist).toFixed(0);
    //         var maxUsersHist = calculateAverage(usersData.dataMaxHist).toFixed(0);
    //         if (vidUsersHist == 'NaN'){
    //             vidUsersHist = '-';
    //         }
    //         if (maxUsersHist == 'NaN') {
    //             maxUsersHist = '-';
    //         }
    //         data[i] = {
    //             name: schools[i].name,
    //             mok_sk: schools[i].custom_fields.mok_sk,
    //             ap_count: schools[i].custom_fields.ap_sk,
    //             vid_paros: (itemDataOne / 1000000).toFixed(2),
    //             max_paros: (itemDataTwo / 1000000).toFixed(2),
    //             vid_users: vidUsersHist,
    //             max_users: maxUsersHist,
    //         }
    //     }
    // }
    return data;
}