import React from "react";
import ErrIcon from "@material-ui/icons/ErrorOutlineRounded";
import DoneIcon from "@material-ui/icons/CheckCircleRounded";
import InfoIcon from "@material-ui/icons/InfoRounded";

function AlertMsg({ msgType, txt }) {
  return (
    <div>
      {msgType === "error" && (
        <div
          className="anim-slide-up"
          style={{
            display: "flex",
            padding: "6px 16px",
            fontSize: "0.875rem",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            lineHeight: "1.43",
            borderRadius: 4,
            letterSpacing: "0.01071em",
            color: "rgb(97, 26, 21)",
            backgroundColor: "rgb(253, 236, 234",
            alignItems: "center",
          }}
        >
          <ErrIcon style={{ color: "red", marginRight: 15 }} />
          {txt}
        </div>
      )}
      {msgType === "success" && (
        <div
          className="anim-slide-up"
          style={{
            display: "flex",
            padding: "6px 16px",
            fontSize: "0.875rem",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            lineHeight: "1.43",
            borderRadius: 4,
            letterSpacing: "0.01071em",
            color: "gb(30, 70, 32)",
            backgroundColor: "rgb(237, 247, 237)",
            alignItems: "center",
          }}
        >
          <DoneIcon style={{ color: "green", marginRight: 15 }} />
          {txt}
        </div>
      )}
      {msgType === "info" && (
        <div
          className="anim-slide-up"
          style={{
            display: "flex",
            padding: "6px 16px",
            fontSize: "0.875rem",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            lineHeight: "1.43",
            borderRadius: 4,
            letterSpacing: "0.01071em",
            color: "rgb(97, 26, 21)",
            backgroundColor: "rgb(232, 244, 253)",
            alignItems: "center",
          }}
        >
          <InfoIcon style={{ color: "#2196f3", marginRight: 15 }} />
          {txt}
        </div>
      )}
    </div>
  );
}

export default AlertMsg;
