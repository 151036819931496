import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import axios from "axios";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

function NavSitesLocationsDropdown({ history }) {
    const [err, setErr] = useState(null);
    const state = useSelector((state) => state.data);
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const [selection, setSelection] = useState(null);
    const [siteLocations, setSiteLocations] = useState(null);
    const [mainSiteId, setMainSiteId] = useState(null);
    const [currentOrg, setCurrentOrg] = useState(null);
    const [searchArray, setSearchArray] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    let { site_id, location_id } = useParams();

    useEffect(() => {
        if (user.role === "admin") {
            if(history.location.pathname == "/") {
                let siteLoc = [];
                axios
                    .post('/api/getSiteLocations', {
                        id: user.organization,
                    })
                    .then((res) => {
                        for(var i = 0; i < res.data.locations.length; i++) {
                            siteLoc[i] = res.data.locations[i];
                        }
                        // siteLoc[res.data.locations.length] = res.data.locations[0].site;
                        setSiteLocations(siteLoc);
                        setSelection(res.data.locations[0].site.name);
                        setMainSiteId(res.data.locations[0].site.id);
                        dispatch({ type: "SET_CURRENT_LOCATION_ID", payload: res.data.locations[0].site.id });
                    })
                    .catch((err) => {
                        console.log(err);
                        setErr(
                            err.response
                                ? err.response.data.error
                                : "Nepavyko gauti loakcijų sąrašo."
                        );
                    });
            } else { 
            // if (state.siteLocations === "location itself") {
                let siteLoc = [];
                axios
                    .post('/api/getSiteLocations', {
                        id: user.organization,
                        site_id: state.organisation.site.id,
                    })
                    .then((res) => {
                        for (var i = 0; i < res.data.locations.length; i++) {
                            siteLoc[i] = res.data.locations[i];
                        }
                        // siteLoc[res.data.locations.length] = state.organisation.site;
                        setSiteLocations(siteLoc);
                        setSelection(state.organisation.name);
                        setMainSiteId(state.organisation.site.id);
                        dispatch({ type: "SET_CURRENT_LOCATION_ID", payload: state.organisation.id });
                    })
                    .catch((err) => {
                        console.log(err);
                        setErr(
                            err.response
                                ? err.response.data.error
                                : "Nepavyko gauti lokacijų sąrašo."
                        );
                    });
            }
        } else if (user.role === "litnet-admin") {
            if (state.siteLocations != "location itself") {
                
                let siteLoc = [];
                for(var i = 0; i < state.siteLocations.length; i++) {
                    siteLoc[i] = state.siteLocations[i];
                }

                // siteLoc[state.siteLocations.length] = state.organisation;

                setSiteLocations(siteLoc);
                setSelection(state.organisation.name);
                setMainSiteId(state.organisation.id);
                dispatch({ type: "SET_CURRENT_LOCATION_ID", payload: state.organisation.id });
            } else {
                let siteLoc = [];
                axios
                    .post('/api/getSiteLocations', {
                        site_id: state.organisation.site.id,
                    })
                    .then((res) => {
                        for(var i = 0; i < res.data.locations.length; i++) {
                            siteLoc[i] = res.data.locations[i];
                        }
                        // siteLoc[res.data.locations.length] = state.organisation.site;
                        setSiteLocations(siteLoc);
                        setSelection(state.organisation.name);
                        setMainSiteId(state.organisation.site.id);
                        dispatch({ type: "SET_CURRENT_LOCATION_ID", payload: state.organisation.id });
                    })
                    .catch((err) => {
                        console.log(err);
                        setErr(
                            err.response
                                ? err.response.data.error
                                : "Nepavyko gauti lokacijų sąrašo."
                        );
                    });
            }
        }
    }, [site_id, location_id]);

    const redirectDevice = (main_id, siteLoc_id) => {
        if (user.role === "admin" && mainSiteId != siteLoc_id) {
            siteLoc_id && history.push(`/${main_id}/${siteLoc_id}`);
        } else if (user.role === "admin" && mainSiteId === siteLoc_id) {
            siteLoc_id && history.push(`/`);
        } else if (user.role === "litnet-admin" && mainSiteId != siteLoc_id) {
            siteLoc_id && history.push(`/inventory/${main_id}/${siteLoc_id}`);
        } else if (user.role === "litnet-admin" && mainSiteId === siteLoc_id ) {
            siteLoc_id && history.push(`/inventory/${main_id}/0`);
        }
    }

    const handleClick = (e) => {
        setSelection(e.name);
        
        // dispatch({ type: "SET_ORGANISATION", payload: null });
        // dispatch({ type: "SET_UNIFI_SITE", payload: null });
        // dispatch({ type: "SET_UNIFI_DATA", payload: null });
        // dispatch({ type: "SET_MT_DATA", payload: null });
        // dispatch({ type: "SET_UNIFI_SWITCH_DATA", payload: null });
        // dispatch({ type: "SET_NETBOX_SITE_DEVICES", payload: null });
        // dispatch({ type: "SET_SITE_LOCATIONS", payload: null });
        dispatch({ type: "SET_CURRENT_LOCATION_ID", payload: e.id });
        dispatch({ type: "SET_SITE_CONTACTS", payload: null });

        redirectDevice(mainSiteId, e.id);
    };

    return (
            <FormControl style={{ width: 400, paddingRight: 15 }} disabled={!siteLocations ? true : false}>
                <Autocomplete
                    options={siteLocations}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => handleClick(value)}
                    renderInput={(params) => <TextField {...params} label={"Skyrius (" + selection + ")"} variant="outlined" />}
                />
                {/* <Select
                    value={!siteLocations ? "" : selection}
                    onChange={(e) => {
                        handleClick(e);
                    }}
                >
                    {siteLocations &&
                        siteLocations.map((item, index) => (
                            <div
                                key={index}
                                className="item-hover "
                                style={{
                                    padding: 7,
                                    cursor: item.id && "pointer",
                                    pointerEvents: !item.id && "none",
                                    opacity: !item.id && 0.3,
                                }}
                                value={item.id ? item.id : 0}
                            >
                                {item.display}
                            </div>
                        ))
                    }
                </Select> */}
            </FormControl>
    );
}

export default withRouter(NavSitesLocationsDropdown);