import { SET_MAIN_PAGE, SET_MIKROTIK_STATE, SET_UNIFIAP_STATE, SET_UNIFISWITCH_STATE, DELETE_MIKROTIK_STATE, SET_SIDEBAR } from "../types";

const initialState = {
  mainPage: { type: "dashboard" },
  mikrotikState:null,
  unifiApState: null,
  unifiSwitchState: null,
  sidebar: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SIDEBAR:
      return {
        ...state,
        sidebar: action.payload,
      };
    case DELETE_MIKROTIK_STATE:
      let newState;
      if(state.mikrotikState[action.payload]){
        newState = {...state.mikrotikState};
        delete newState[action.payload]
      }
      return {
        ...state,
        unifiSwitchState: newState,
      };
    case SET_UNIFISWITCH_STATE:
      return {
        ...state,
        unifiSwitchState: action.payload,
      };
    case SET_UNIFIAP_STATE:
      return {
        ...state,
        unifiApState: action.payload,
      };
    case SET_MIKROTIK_STATE:
      return {
        ...state,
        mikrotikState: action.payload,
      };
    case SET_MAIN_PAGE:
      return {
        ...state,
        mainPage: action.payload,
      };

    default:
      return state;
  }
}
