import {
  SET_ORGANISATION,
  SET_MIKROTIK_STATE,
  SET_UNIFI_DATA,
  SET_MT_DATA,
  SET_UNIFI_SWITCH_DATA,
} from "../types";
import axios from "axios";

export const getUnfiSwitchStats =
  (mac, port, dateFrom, dateTo, hostId, ip) => (dispatch, getState) => {
    const login = getState().data.organisation.unifiLogin;
    const slug = getState().data.organisation.slug;
    return axios.post("/api/switchstats", {
      slug: slug,
      url: login.url,
      site: login.site,
      mac: mac,
      port,
      dateFrom: dateFrom,
      dateTo: dateTo,
      hostId: hostId,
      ip,
    });
  };

export const getUnfiApStats =
  (ap, hostId, dateFrom, dateTo, ip) => (dispatch, getState) => {
    const login = getState().data.organisation.unifiLogin;
    const slug = getState().data.organisation.slug;
    return axios.post(
      "/api/apstats",
      {
        login: login.login,
        password: login.password,
        url: login.url,
        site: login.site,
        ap: ap,
        slug: slug,
        hostId: hostId,
        dateFrom: dateFrom,
        dateTo: dateTo,
        ip: ip,
      }
      // { cancelToken: CancelToken.token }
    );
  };

export const SwitchDataUpdate = (mac) => (dispatch, getState) => {
  const login = getState().data.organisation.unifiLogin;

  axios
    .post(
      "/api/unifiswitch",
      {
        login: login.login,
        password: login.password,
        url: login.url,
        site: login.site,
        mac: mac,
      }
      // { cancelToken: CancelToken.token }
    )
    .then((res) => {
      if (res.data.result && res.data.result.devices.length) {
        const state = getState().data;
        const item = res.data.result.devices[0];

        const newData = { ...state.unifiSwitchData };
        const index = newData.devices.findIndex((x) => x.mac === item.mac);
        if (index > -1) {
          newData.devices[index] = item;
        }

        dispatch({
          type: SET_UNIFI_SWITCH_DATA,
          payload: newData,
        });
      }
    });
};

export const MTDataUpdate = (device) => (dispatch) => {
  if (
    device &&
    device.config_context.login &&
    device.custom_fields["IP_adresas"]
  ) {
    axios
      .post("/api/getmtrouterinfo", {
        login: device.local_context_data.login,
        url: device.custom_fields["IP_adresas"],
      })
      .then((res) => {
        dispatch({ type: SET_MT_DATA, payload: res.data });
      });
  }
};

export const UnifiAPUpdate = (mac) => (dispatch, getState) => {
  const login = getState().data.organisation.unifiLogin;

  return axios.post(
    "/api/unifiapmain",
    {
      login: login.login,
      password: login.password,
      url: login.url,
      site: login.site,
      mac: mac,
    }
    // { cancelToken: CancelToken.token }
  );
};

export const UnifiAlarms = () => (dispatch, getState) => {
  const login = getState().data.organisation.unifiLogin;

  axios
    .post(
      "/api/unifialarms",
      {
        login: login.login,
        password: login.password,
        url: login.url,
        site: login.site,
      }
      // { cancelToken: CancelToken.token }
    )
    .then((res) => {
      if (res.data.result) {
        const state = getState().data;

        const newUnifiSiteData = { ...state.unifiSiteData };
        newUnifiSiteData.events = res.data.result.events;

        dispatch({
          type: SET_UNIFI_DATA,
          payload: newUnifiSiteData,
        });
      }
    });
};

export const UnifiClients = () => (dispatch, getState) => {
  const login = getState().data.organisation.unifiLogin;

  axios
    .post(
      "/api/unificlients",
      {
        login: login.login,
        password: login.password,
        url: login.url,
        site: login.site,
      }
      // { cancelToken: CancelToken.token }
    )
    .then((res) => {
      if (res.data.result && res.data.result.clients) {
        const state = getState().data;
        const filteredDevices = res.data.result.devices
          .filter((dev) => dev.type === "uap" && dev.serial)
          .filter(function (o1) {
            return state.netboxSiteDevices.some(function (o2) {
              return o1.serial.toLowerCase() === o2.serial.toLowerCase();
            });
          });
        const filteredClients = res.data.result.clients
          .filter((dev) => dev.ap_mac !== undefined)
          .filter(function (o1) {
            return filteredDevices.some(function (o2) {
              return o1.ap_mac.toLowerCase() === o2.mac.toLowerCase();
            });
          });
        const newUnifiSiteData = { ...state.unifiSiteData };
        newUnifiSiteData.clients = filteredClients;

        dispatch({
          type: SET_UNIFI_DATA,
          payload: newUnifiSiteData,
        });
      }
    });
};

export const UnifiInfoUpdate = (role) => (dispatch, getState) => {
  const login = getState().data.organisation.unifiLogin;
  axios
    .post(
      "/api/unifisite",
      {
        login: login.login,
        password: login.password,
        url: login.url,
        site: login.site,
        role: role,
      }
      // { cancelToken: CancelToken.token }
    )
    .then((res) => {
      const state = getState().data;
      let finalData = res.data.result;
      if (state.netboxSiteDevices && state.netboxSiteDevices.length > 0) {
        const filteredDevices = res.data.result.devices
          .filter((dev) => dev.type === "uap" && dev.serial)
          .filter(function (o1) {
            return state.netboxSiteDevices.some(function (o2) {
              return o1.serial.toLowerCase() === o2.serial.toLowerCase();
            });
          });
        const filteredClients = res.data.result.clients
          .filter((dev) => dev.ap_mac !== undefined)
          .filter(function (o1) {
            return filteredDevices.some(function (o2) {
              return o1.ap_mac.toLowerCase() === o2.mac.toLowerCase();
            });
          });

        finalData.devices = filteredDevices;
        finalData.clients = filteredClients;
        const updatedNetboxDevices = state.netboxSiteDevices.map((dev) => {
          const name = dev.name.includes(" ")
            ? dev.name.split(" ")[1]
            : dev.name;

          const unifiDevice = filteredDevices.find(
            (x) => x.serial.toLowerCase() === dev.serial.toLowerCase()
          );

          if (unifiDevice) {
            dev.state = unifiDevice.state;
            dev.unifiName = unifiDevice.name ? unifiDevice.name : name;
            return dev;
          }

          return dev;
        });
        dispatch({
          type: "SET_NETBOX_SITE_DEVICES",
          payload: updatedNetboxDevices,
        });
      }
      const newUnifiSiteData = { ...state.unifiSiteData };
      newUnifiSiteData.devices = finalData.devices;
      newUnifiSiteData.clients = finalData.clients;
      newUnifiSiteData.dailyStats = finalData.dailyStats;
      newUnifiSiteData.dashboard = finalData.dashboard;
      newUnifiSiteData.health = finalData.health;
      newUnifiSiteData.sysinfo = finalData.sysinfo;
      dispatch({
        type: SET_UNIFI_DATA,
        payload: newUnifiSiteData,
      });
    });
};

export const setOrganisation = (org) => (dispatch) => {
  const organisation = { ...org };
  if (organisation.description) {
    const row = organisation.description.split(/(\s+)/);
    const login = row.filter((x) => x.includes("login="));
    const url = row.filter((x) => x.includes("url="));
    const pass = row.filter((x) => x.includes("password="));
    const site = row.filter((x) => x.includes("site="));
    if (
      login &&
      url &&
      pass &&
      site &&
      login.length &&
      url.length &&
      pass.length &&
      site.length
    ) {
      let dt = {};
      const d1 = login[0].split("=")[1];
      const d2 = url[0].split("=")[1];
      const d3 = pass[0].split("=")[1];
      const d4 = site[0].split("=")[1];
      dt.login = d1;
      dt.password = d3;
      dt.url = d2;
      dt.site = d4;
      organisation.unifiLogin = dt;
    }
  }
  dispatch({ type: SET_ORGANISATION, payload: organisation });
};

export const setMTState = (st) => (dispatch, getState) => {
  const oldSt = getState().ui.mikrotikState;
  if (oldSt && st && st.id) {
    oldSt[st.id] = { ...st };
    dispatch({ type: SET_MIKROTIK_STATE, payload: oldSt });
    return;
  }
  if (st && st.id) {
    const newSt = {
      [st.id]: {
        ...st,
      },
    };

    dispatch({ type: SET_MIKROTIK_STATE, payload: { ...newSt, ...oldSt } });
    return;
  }
  dispatch({ type: SET_MIKROTIK_STATE, payload: st });
};

export const getOrganisation = (org) => {
  const organisation = { ...org };

  if (organisation.description) {
    const row = organisation.description.split(/(\s+)/);
    const login = row.filter((x) => x.includes("login="));
    const url = row.filter((x) => x.includes("url="));
    const pass = row.filter((x) => x.includes("password="));
    const site = row.filter((x) => x.includes("site="));
    if (
      login &&
      url &&
      pass &&
      site &&
      login.length &&
      url.length &&
      pass.length &&
      site.length
    ) {
      let dt = {};
      const d1 = login[0].split("=")[1];
      const d2 = url[0].split("=")[1];
      const d3 = pass[0].split("=")[1];
      const d4 = site[0].split("=")[1];
      dt.login = d1;
      dt.password = d3;
      dt.url = d2;
      dt.site = d4;
      organisation.unifiLogin = dt;
    }
  }
  return organisation;
};

export const countAP = (data) => {
  var count = 0;
  if(Object.prototype.toString.call(data) === '[object Array]'){
    for (var i = 0; i < data.length; i++){
      if(data[i].name.includes("Unifi-AP")){
        count += 1;
      }
    }
  }
  return count;
}

// async function getAp(id) {
//   var apData;
//   await axios
//     .post("/api/getdeviceslist", { site_id: id, ap: true })
//     .then((res) => {
//       apData = res.data.result.results;   
//     })
//     .catch((err) => {
//       console.log(err.response.data.error);
//     });

//     return apData;
// }

export const buildStatisticsData = (schoolData) => {
  var data = [];
  for(var i = 0; i < schoolData.searchData.length; i++){
    var organisation = getOrganisation(schoolData.searchData[i]);
    var apData = null;
    data[i] = {
      name: organisation.name,
      ap_count: organisation.device_count,
      avg_rx: 0,
    }
  }
  return data;

} 

//export const test = (data) => {
// export async function test(data) {
//   var tableData = [];
//   for(var i = 0; i < data.length; i++) {
//     var ap = await getAp(data[i].id);
//     var apCount = countAP(await getAp(data[i].id));
//     tableData[i] = {
//       name: data[i].name,
//       ap_count: apCount,
//       avg_rx: 0,
//     }
//   }

//   return tableData;
// }
