import React, { useState, lazy } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import Logo from "../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/actions/userActions";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import NavSitesSelect from "../components/NavSitesDropdown";
import NavSitesLocationsSelect from "../components/NavSitesLocationsDropdown";
import AdminDashboard from "./AdminDashboard";
import SetingsIcon from "@material-ui/icons/SettingsRounded";
import BarChartRounded from "@material-ui/icons/BarChartRounded";
import AdminSitesDropdown from "./AdminSitesDropdown";

import { useHistory}  from "react-router-dom";

import StatisticsPage from "../pages/Statistics";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.75)",
  },
}))(Tooltip);
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "2px solid #e0e0e0",
    backgroundColor: "#fafafa",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function PrimarySearchAppBar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const state = useSelector((state) => state.data);
  const classes = useStyles();
  const [adminModal, setAdminModal] = useState(false);
  const [statsPage, setStatsPage] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const LogoutUser = () => {
    handleMenuClose();
    dispatch(logoutUser());
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={LogoutUser}>Atsijungti</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {userState.user && userState.user.role === "litnet-admin" && (
        <HtmlTooltip
          title={
            <div style={{ padding: 5, fontSize: "0.8rem" }}>
              Sistemos administravimo skydelis
            </div>
          }
          placement="top"
        >
          <IconButton onClick={() => setAdminModal(true)}>
            <SetingsIcon
              style={{
                fontSize: "2rem",
              }}
            />
          </IconButton>
        </HtmlTooltip>
      )}
      <MenuItem onClick={LogoutUser}>Atsijungti</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar
        //position="fixed"
        color="white"
        style={{
          boxShadow: "none",
          zIndex: 99,
          boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.75)",
        }}
      >
        {adminModal && <AdminDashboard close={setAdminModal} />}
        
        <Toolbar>
          <div style={{ marginRight: "2%" }}>
            <Link to="/">
              <img
                src={Logo}
                alt="Logo"
                style={{ width: "auto", height: 35 }}
              />
            </Link>
          </div>
          <div style={{ marginRight: "5%" }}>
            <small
              style={{
                margin: 0,
                padding: 0,
                opacity: 0.8,
                fonFamily: "InterUI, sans-serif",
              }}
            >
              Belaidžių zonų
            </small>
            <Typography
              className={classes.title}
              variant="subtitle1"
              noWrap
              style={{ margin: 0, padding: 0 }}
            >
              Stebėsenos ir valdymo sistema
            </Typography>
          </div>
          {
            userState.user &&
            userState.user.role === "admin" &&
            state.organisation && (
              <div
                style={{
                  marginTop: 5,
                  marginRight: 25,
                  // display: "flex",
                  // alignItems: "center",
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {state.organisation.name}
              </div>
            )
          }
          {/* {userState.user &&
          userState.user.role === "admin" &&
          state.searchData &&
          state.searchData.length > 0 ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "1%",
              }}
            >
              <AdminSitesDropdown />
            </div>
          ) : (
            userState.user &&
            userState.user.role === "admin" &&
            state.organisation && (
              <div
                style={{
                  marginTop: 5,
                  marginRight: 25,
                  // display: "flex",
                  // alignItems: "center",
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {state.organisation.name}
              </div>
            )
          )} */}

          

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {!history.location.pathname.includes("/statistics") &&  userState.user && (userState.user.role === "litnet-admin" || userState.user.role === "admin") && (state.siteLocations === 'location itself' || state.siteLocations != null) && state.organisation && (
              <div style={{ marginTop: 5, marginLeft: 10 }}>
                {/* Select Location if exists */}
                <NavSitesLocationsSelect />
              </div>
            )}

            {!history.location.pathname.includes("/statistics") && userState.user && userState.user.role === "litnet-admin" && (
              <div style={{ marginTop: 5, marginLeft: 10 }}>
                <NavSitesSelect />
              </div>
            )}

            {userState.user && userState.user.role === "litnet-admin" && (
              <HtmlTooltip
                title={
                  <div style={{ padding: 5, fontSize: "0.8rem" }}>
                    Statistikos
                  </div>
                }
                placement="top"
              >
                <IconButton onClick={() => {
                  history.push(`/statistics`)
                  setStatsPage(true)
                  }}>
                  <BarChartRounded
                    style={{
                      fontSize: "1.8rem",
                    }}
                  />
                </IconButton>
              </HtmlTooltip>
            )}
            <div style={{ marginLeft: 0 }} />
            {userState.user && userState.user.role === "litnet-admin" && (
              <HtmlTooltip
                title={
                  <div style={{ padding: 5, fontSize: "0.8rem" }}>
                    Sistemos administravimo skydelis
                  </div>
                }
                placement="top"
              >
                <IconButton onClick={() => {
                  setAdminModal(true)
                  setStatsPage(true)
                  }}>
                  <SetingsIcon
                    style={{
                      fontSize: "1.8rem",
                    }}
                  />
                </IconButton>
              </HtmlTooltip>
            )}
            <div style={{ marginLeft: 0 }} />
            {userState.user && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 60,
                  fontWeight: "bold",
                  marginTop: 5,
                }}
              >
                {" "}
                {userState.user && userState.user.name && userState.user.name}
              </div>
            )}
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {/* {statsPage && <StatisticsPage close={setStatsPage} />} */}
    </div>
  );
}