import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@material-ui/core/IconButton";
export default function Confirm({ reboot, closeModal, title, txt, btTxt }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="modal-close-btn">
          {" "}
          <IconButton onClick={() => closeModal()}>
            <CloseIcon />
          </IconButton>
        </div>

        <DialogTitle
          id="alert-dialog-title"
          style={{ borderBottom: "1px solid #F5F5F5" }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ marginTop: 10 }}
          >
            {txt}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div
            style={{ width: "100%", marginBottom: 15 }}
            className="center-flex"
          >
            <Button
              style={{ background: "#4CAF50", color: "white" }}
              onClick={() => {
                reboot();
                closeModal();
              }}
              color="primary"
            >
              {btTxt ? btTxt : "Prekrauti"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
