import React, { useState, useEffect, useRef } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import AlertMsg from "../AlertMsg";

function LogModal({ close }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [err, setErr] = useState(null);
  const messageRef = useRef();

  useEffect(() => {
    setErr(null);
    setLoading(true);
    axios
      .get("/api/unifilog")
      .then((res) => {
        setData(res.data);
        if (messageRef.current) {
          messageRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setErr(err.response ? err.response.data : "Sistemos klaida");
        setLoading(false);
      });
  }, []);
  return (
    <Dialog
      onClose={() => close(false)}
      aria-labelledby="simple-dialog-title"
      open={true}
      maxWidth="xl"
    >
      <DialogTitle id="simple-dialog-title">Atnaujinimo log</DialogTitle>
      <DialogContent
        style={{ backgroundColor: data && "#f5f5f5", margin: "1%" }}
      >
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              overflowX: "none",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {err && (
          <div
            style={{
              padding: 7,
              marginBottom: 15,
            }}
          >
            <AlertMsg msgType={"error"} txt={err} />
          </div>
        )}
        {data && <pre style={{ whiteSpace: "pre-line" }}>{data}</pre>}
        <div ref={messageRef} style={{ marginTop: 50 }} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" autoFocus onClick={() => close(false)}>
          Uždaryti
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LogModal;
