import React, { useState, useEffect } from "react";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useDispatch } from "react-redux";
import AddIcon from "@material-ui/icons/AddRounded";
import Creator from "./Creator";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import ConfirmModal from "../ConfirmModal";

function InifiUrlList({ urls, setUrls, setErr, createMode }) {
  const [data, setData] = useState(null);
  const [creator, setCreator] = useState(false);
  const [loading, setLoading] = useState(null);
  const [modal, setModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setErr(null);
    setLoading(true);
    axios
      .get("/api/unifiurls")
      .then((res) => {
        const checks = res.data.map((item) => {
          return { ...item, checked: false };
        });
        setUrls(checks);
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setErr(err.response ? err.response.data : "Sistemos klaida");
        setLoading(false);
      });
  }, []);
  const deleteUrls = () => {
    setErr(null);
    setLoading(true);
    const datas = urls.filter((x) => x.checked);
    axios
      .delete("/api/unifiurls", { data: datas })
      .then((res) => {
        const checks = res.data.map((item) => {
          return { ...item, checked: false };
        });
        setUrls(checks);
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setErr("Sistemos klaida.");
        setLoading(false);
      });
  };
  const handleCheck = (e, url) => {
    const newUrl = { ...url };
    const newUrls = [...urls];
    newUrl.checked = e.target.checked;
    const index = newUrls.findIndex((x) => x.id === newUrl.id);
    newUrls[index] = newUrl;
    setUrls(newUrls);
  };
  return (
    <div style={{ width: "100%", position: "relative" }}>
      {modal && (
        <ConfirmModal
          closeModal={() => setModal(false)}
          reboot={deleteUrls}
          title={
            <div style={{ marginRight: 20 }}>
              Ar tikrai norite ištrinti adresatą(-us)?
            </div>
          }
          txt={"Pažymėti įrašai bus ištrinti."}
          btTxt={"Ištrinti"}
        />
      )}
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            overflowX: "none",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div>
        {" "}
        {createMode && (
          <div
            style={{
              display: "flex",
              alignItems: "center",

              justifyContent: "flex-end",
              position: "absolute",
              right: 10,
              top: creator && 100,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "flex-end",
              }}
              onClick={() => setCreator(!creator)}
            >
              <AddIcon
                style={{
                  marginRight: 5,
                  transform: creator && "rotate(45deg)",
                  transition: "all 0.7s",

                  marginBottom: 2,
                }}
              />{" "}
              <div>{creator ? "Uždaryti" : "Pridėti adresatą"}</div>
            </div>

            {urls && urls.filter((x) => x.checked).length > 0 && (
              <div
                style={{
                  display: "flex",
                  marginLeft: 15,
                }}
              >
                <DeleteIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setModal(true)}
                />
              </div>
            )}
          </div>
        )}
        {creator && (
          <div style={{ marginTop: 15 }}>
            {" "}
            <Creator
              setErr={setErr}
              setUrls={setUrls}
              setLoading={setLoading}
            />
          </div>
        )}
        <div style={{ marginTop: 15 }}>
          {urls &&
            urls.length > 0 &&
            urls.map((url, index) => (
              <div key={index}>
                {" "}
                <Checkbox
                  checked={url.checked}
                  onChange={(e) => handleCheck(e, url)}
                  // inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                {url.url}
              </div>
            ))}
          {urls && urls.length === 0 && <div>Pridėtų adresų nėra.</div>}
        </div>
      </div>
    </div>
  );
}

export default InifiUrlList;
