import React, { useState } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import UnifUrls from "./UnifiUrlList";
import AlertMsg from "../AlertMsg";
import CronUnifiUpdate from "./CronUnifiUpdate";

function Settings({ urls, setUrls }) {
  const [err, setErr] = useState(null);
  const [modal, setModal] = useState(false);

  return (
    <DialogContent style={{ marginTop: 10, width: 1000, minHeight: 500 }}>
      {err && (
        <div
          style={{
            padding: 7,
          }}
        >
          <AlertMsg msgType={"error"} txt={err} />
        </div>
      )}
      <div style={{ padding: "1%" }}>
        {/* <hr
          style={{
            margin: 0,
            padding: 0,
            marginBottom: 10,
            opacity: 0.4,
          }}
        /> */}
        <small
          style={{
            opacity: 0.6,

            color: "#3f51b5",
            marginBottom: 100,
          }}
        >
          Unifi kontrolerio URL pridėjimas/ištrynimas
        </small>
        <br />
        <UnifUrls
          urls={urls}
          setUrls={setUrls}
          setErr={setErr}
          setModal={setModal}
          createMode={true}
        />
        <hr
          style={{
            margin: 0,
            padding: 0,
            marginTop: 25,
            marginBottom: 10,
            opacity: 0.4,
          }}
        />
        <small
          style={{
            opacity: 0.6,

            color: "#3f51b5",
            marginBottom: 100,
          }}
        >
          Periodinis Unifi įrenginių sąrašo atnaujinimas
        </small>
        <CronUnifiUpdate setErr={setErr} />
      </div>
    </DialogContent>
  );
}

export default Settings;
