import React, { useState, useEffect } from "react";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

function UnifiUrlSelect({ setUnifiObj, setErr, urls, setUrls }) {
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/api/unifiurls")
      .then((res) => {
        setUrls(res.data);
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setErr(err.response ? err.response.data : "Sistemos klaida");
        setLoading(false);
      });
  }, []);
  return (
    <div>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            overflowX: "none",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {urls && (
        <FormControl>
          <InputLabel htmlFor="age-native-helper">Unifi URL</InputLabel>
          <NativeSelect
            //   value={state.age}
            onChange={(e) => setUnifiObj(JSON.parse(e.target.value))}
            inputProps={{
              name: "age",
              id: "age-native-helper",
            }}
          >
            <option aria-label="None" value={"{}"} />
            {urls.map((item, index) => (
              <option value={JSON.stringify(item)}>{item.url}</option>
            ))}
          </NativeSelect>
        </FormControl>
      )}
    </div>
  );
}

export default UnifiUrlSelect;
