import React from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setOrganisation } from "../redux/actions/dataActions";
import NativeSelect from "@material-ui/core/NativeSelect";

function AdminSitesDropdown() {
  const state = useSelector((state) => state.data);
  const dispatch = useDispatch();

  const handleCLick = (val) => {

    const site = JSON.parse(val);
    dispatch(setOrganisation(site));
    dispatch({ type: "SET_UNIFI_SITE", payload: null });
    dispatch({ type: "SET_UNIFI_DATA", payload: null });
    dispatch({ type: "SET_MT_DATA", payload: null });
    dispatch({ type: "SET_UNIFI_SWITCH_DATA", payload: null });
    dispatch({ type: "SET_NETBOX_SITE_DEVICES", payload: null });
    dispatch({ type: "SET_SITE_LOCATIONS", payload: null });

    axios
      .post("/api/devicelist", {
        id: site.id,
      })
      .then((res) => {
        dispatch({
          type: "SET_NETBOX_SITE_DEVICES",
          payload: res.data.result.results,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* <Autocomplete 
        options={state.searchData}
      /> */}
      <NativeSelect onChange={(e) => handleCLick(e.target.value)}>
        {state.searchData.map((item, index) => (
          <option value={JSON.stringify(item)}>{item.name}</option>
        ))}
      </NativeSelect>
    </div>
  );
}

export default AdminSitesDropdown;
