import {
  SET_SEARCH_DATA,
  SET_NETBOX_SITE_DEVICES,
  SET_UNIFI_SITE,
  SET_UNIFI_DATA,
  SET_MT_DATA,
  SET_ORGANISATION,
  SET_UNIFI_SWITCH_DATA,
  SET_LOADING,
  SET_WIFI_SUM,
  SET_LAST_AP_TXRX,
  SET_LAST_USER_SUM,
  SCHOOLS_STATISTICS_DATA,
  FIRST_SCHOOLS_STATISTICS_DROPDOWN_SCHOOLS,
  SECOND_SCHOOLS_STATISTICS_DROPDOWN_REGIONS,
  SCHOOLS_STATISTICS_TABLE_DATA,
  ORGANISATIONS_DROPDOWN,
  WAREHOUSE_DEVICES_DROPDOWN,
  UNIFI_URL_DROPDOWN,
  SET_SITE_LOCATIONS,
  SET_CURRENT_LOCATION_ID,
  SET_SITE_CONTACTS,
  SET_ALL_LOCATIONS,
} from "../types";

const initialState = {
  searchData: [],
  netboxSiteDevices: null,
  unifiSite: null,
  unifiSiteData: null,
  mikrotikData: null,
  organisation: null,
  unifiSwitchData: null,
  unifiWifiSum: null,
  loading: false,
  lastApTxRx: null,
  apUserSum: null,
  schoolStatsData: null,
  firstSchoolsStatsDropdownSchools: null,
  secondSchoolsStatsDropdownRegions: null,
  schoolsStatisticsTableData: [],
  orgDropdown: null,
  warehouseDevicesDropdown: null,
  unifiUrlDropdown: null,
  siteLocations: null,
  currentLocationId: null,
  siteContacts: null,
  allLocations: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LAST_USER_SUM:
      return {
        ...state,
        apUserSum: action.payload,
      };
    case SET_LAST_AP_TXRX:
      return {
        ...state,
        lastApTxRx: action.payload,
      };
    case SET_WIFI_SUM:
      return {
        ...state,
        unifiWifiSum: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_UNIFI_SWITCH_DATA:
      return {
        ...state,
        unifiSwitchData: action.payload,
      };
    case SET_ORGANISATION:
      return {
        ...state,
        organisation: action.payload,
      };
    case SET_MT_DATA:
      return {
        ...state,
        mikrotikData: action.payload,
      };
    case SET_UNIFI_DATA:
      return {
        ...state,
        unifiSiteData: action.payload,
      };
    case SET_SEARCH_DATA:
      return {
        ...state,
        searchData: action.payload,
      };
    case SET_NETBOX_SITE_DEVICES:
      return {
        ...state,
        netboxSiteDevices: action.payload,
      };
    case SET_UNIFI_SITE:
      return {
        ...state,
        unifiSite: action.payload,
      };
    case SCHOOLS_STATISTICS_DATA:
      return {
        ...state,
        schoolStatsData: action.payload,
      };
    case FIRST_SCHOOLS_STATISTICS_DROPDOWN_SCHOOLS:
      return {
        ...state,
        firstSchoolsStatsDropdownSchools: action.payload,
      };
    case SECOND_SCHOOLS_STATISTICS_DROPDOWN_REGIONS:
      return {
        ...state,
        secondSchoolsStatsDropdownRegions: action.payload,
      };
    case SCHOOLS_STATISTICS_TABLE_DATA:
      return {
        ...state,
        schoolsStatisticsTableData: action.payload,
      };
    case ORGANISATIONS_DROPDOWN:
      return {
        ...state,
        orgDropdown: action.payload,
      };
    case WAREHOUSE_DEVICES_DROPDOWN:
      return {
        ...state,
        warehouseDevicesDropdown: action.payload,
      };
    case UNIFI_URL_DROPDOWN:
      return {
        ...state,
        unifiUrlDropdown: action.payload,
      }
    case SET_SITE_LOCATIONS:
      return {
        ...state,
        siteLocations: action.payload,
      }
    case SET_CURRENT_LOCATION_ID:
      return {
        ...state,
        currentLocationId: action.payload,
      }
    case SET_SITE_CONTACTS:
      return {
        ...state,
        siteContacts: action.payload,
      }
    case SET_ALL_LOCATIONS:
      return {
        ...state,
        allLocations: action.payload,
      }

    default:
      return state;
  }
}