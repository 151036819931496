import {
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  SET_USER,
  LOADING_USER,
  STOP_LOADING_USER,
  SET_USER_ERROR,
} from "../types";

const initialState = {
  authenticated: false,
  loading: false,
  user: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
        user: null,
        loading: false,
      };
    case SET_UNAUTHENTICATED:
      return {
        ...state,
        authenticated: false,
        user: null,
      };
    case SET_USER:
      return {
        ...state,
        authenticated: true,
        user: action.payload,
        loading: false,
      };
    case STOP_LOADING_USER:
      return {
        ...state,
        loading: false,
      };
    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}
