import React, { useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AlertMsg from "../AlertMsg";
import Tooltip from "@material-ui/core/Tooltip";
import LogIcon from "@material-ui/icons/DescriptionRounded";
import LogModal from "./LogModal";

function CronUnifiUpdate() {
  const [cron, setCron] = useState("0 0 * * *");
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(null);
  const [edited, setEdited] = useState(false);
  const [logModal, setLogModal] = useState(false);
  const [err, setErr] = useState(null);
  useEffect(() => {
    setErr(null);
    setLoading(true);
    axios
      .get("/api/unificron")
      .then((res) => {
        if (res.data.enabled) {
          setEnabled(true);
          setCron(res.data.cron);
        } else {
          setEnabled(false);
        }
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setErr(err.response ? err.response.data : "Sistemos klaida");
        setLoading(false);
      });
  }, []);

  const handleSubmit = () => {
    setErr(null);
    setLoading(true);
    axios
      .post("/api/unificron", { enabled: enabled, cron: cron })
      .then((res) => {
        setEdited(false);
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setErr(err.response ? err.response.data : "Sistemos klaida");
        setLoading(false);
      });
  };

  return (
    <div style={{ marginTop: 25 }}>
      {logModal && <LogModal close={setLogModal} />}
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            overflowX: "none",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {err && (
        <div
          style={{
            padding: 7,
            marginBottom: 15,
          }}
        >
          <AlertMsg msgType={"error"} txt={err} />
        </div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {" "}
          <Tooltip
            title={`${enabled ? "Išjungti" : "Įjungti"} periodinį atnaujinimą`}
            placement={"top"}
          >
            <Checkbox
              checked={enabled}
              disabled={loading}
              onChange={(e) => {
                !edited && setEdited(true);
                setEnabled(e.target.checked);
              }}
              // inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Tooltip>
          <TextField
            style={{ marginLeft: 15, width: 150, textAlign: "center" }}
            size="small"
            label="Cron sintaksė"
            variant="outlined"
            disabled={!enabled || loading}
            value={cron}
            onChange={(e) => {
              !edited && setEdited(true);
              setCron(e.target.value);
            }}
          />
          {edited && (
            <Tooltip title={`Išsaugoti pakeitimus`} placement={"top"}>
              <Button
                style={{ marginLeft: 30 }}
                variant="outlined"
                onClick={() => handleSubmit()}
                disabled={loading}
              >
                Pateikti
              </Button>
            </Tooltip>
          )}
        </div>
        <Tooltip title={`Peržiūrėti atnaujinimo log'us`} placement={"top"}>
          <LogIcon
            onClick={() => setLogModal(!logModal)}
            style={{ color: "#696969", cursor: "pointer", marginLeft: 30 }}
          />
        </Tooltip>
      </div>
    </div>
  );
}

export default CronUnifiUpdate;
