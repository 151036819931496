import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import axios from "axios";

function Creator({ setUrls, setErr, setLoading }) {
  const [formData, setFormData] = useState({
    url: "",
    name: "",
    password: "",
  });
  const handleText = (e) => {
    const newData = { ...formData };
    newData[e.target.name] = e.target.value;
    setFormData(newData);
  };

  const handleSubmit = () => {
    setErr(null);
    if (!formData.url || !formData.name || !formData.password) {
      setErr("Būtina užpildyti visus laukus!");
      return;
    }
    setLoading(true);
    axios
      .post("/api/unifiurls", { data: formData })
      .then((res) => {
        const checks = res.data.map((item) => {
          return { ...item, checked: false };
        });
        setUrls(checks);
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setErr(err.response ? err.response.data : "Sistemos klaida");
        setLoading(false);
      });
  };

  return (
    <div
      className="anim-slide-down"
      style={{
        // backgroundColor: "#f5f5f5",
        width: "100%",
        marginBottom: 15,
      }}
    >
      <hr style={{ marginTop: 20, opacity: 0.2 }} />
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          name="url"
          label="URL"
          style={{
            flex: "1 1 90px",
            margin: 10,
            // marginTop: 20,
            // marginRight: 25,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">https://</InputAdornment>
            ),
            endAdornment: <InputAdornment position="end">:8443</InputAdornment>,
          }}
          size="small"
          onChange={(e) => handleText(e)}
          value={formData.url}
        />
        <TextField
          name="name"
          label="Prisijungimo vardas"
          style={{ flex: "1 1 100px", margin: 10 }}
          required
          size="small"
          onChange={(e) => handleText(e)}
          value={formData.name}
        />
        <TextField
          name="password"
          label="Slaptažodis"
          style={{ flex: "1 1 100px", margin: 10 }}
          required
          size="small"
          onChange={(e) => handleText(e)}
          value={formData.password}
        />
        <Button variant="contained" style={{}} onClick={() => handleSubmit()}>
          Sukurti
        </Button>
      </div>
    </div>
  );
}

export default Creator;
