import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
// import CreateNetboxSite from "./AddNewSite";
import CreateNetboxSite from "./OrganisationMicrotikAdd";
import UpdateWifiDevices from "./UpdateNetboxDevices";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SetingsIcon from "@material-ui/icons/SettingsRounded";
import Settings from "./device_update/Settings";

function UpdateNetboxDevices({ close }) {
  const [page, setPage] = React.useState(0);
  const [urls, setUrls] = useState(null);
  const handleChange = (event, newValue) => {
    setPage(newValue);
  };
  return (
    <Dialog
      open={true}
      onClose={() => close()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          borderBottom: "1px solid #F5F5F5",
          height: "2rem",
          lineHeight: "2rem",
          verticalAlign: "center",
        }}
      >
        <SetingsIcon
          style={{ marginRight: 25, position: "absolute", top: 19 }}
        />{" "}
        <div style={{ marginLeft: 35 }}> Sistemos administravimo skydelis</div>
      </DialogTitle>

      <div style={{ marginLeft: 25, fontSize: "2rem" }}>
        {" "}
        <Tabs
          value={page}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          {/* <Tab label="Pridėti organizaciją" /> */}
          <Tab label="Naujas jungimas" />
          <Tab label="Atnaujinti WIFI įrenginius" />
          <Tab label="Nustatymai" />
        </Tabs>
      </div>

      {page === 0 && <CreateNetboxSite urls={urls} setUrls={setUrls} />}
      {page === 1 && <UpdateWifiDevices />}
      {page === 2 && <Settings urls={urls} setUrls={setUrls} />}
    </Dialog>
  );
}

export default UpdateNetboxDevices;
