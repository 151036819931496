import React from "react";

const ContainerWrapper = (props) => {
  return (
    <div
      className="wrapper-main"
      style={{
        ...props.styles,
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          borderRadius: 8,
          backgroundImage:
            "linear-gradient(146deg, #f5f5f5 8.33%, #fafafa 8.33%, #fafafa 50%, #f5f5f5 50%, #f5f5f5 58.33%, #fafafa 58.33%, #fafafa 100%)",
          padding: 4,
          backgroundSize: "10.73px 7.24px",
          paddingLeft: 5,
          paddingTop: 15,
          borderBottom: "1px solid #f5f5f5",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <h4
            style={{
              margin: 0,
              padding: 0,
              textAlign: "left",
              fontFamily: "Trebuchet MS, Helvetica, sans-serif",
              opacity: "0.9",
              marginLeft: "1%",
              fontSize: "0.8rem",
              fontWeight: "bold",
            }}
          >
            {props.title.toUpperCase()}
          </h4>
          <div style={{ marginRight: "0.5%" }}>{props.opt}</div>
        </div>
        <p
          style={{
            margin: 0,
            padding: 0,
            textAlign: "left",
            fontFamily: "Trebuchet MS, Helvetica, sans-serif",
            fontSize: "0.7rem",
            color: "#c0c0c0",
            marginLeft: "1%",
          }}
        >
          {props.sub}
        </p>
      </div>
      {props.children}
    </div>
  );
};

export default ContainerWrapper;
