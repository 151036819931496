import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import axios from "axios";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

function NavSitesDropdown({ history }) {
  const location = useLocation();
  const state = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const [selection, setSelection] = useState(null);
  const [sites, setSites] = useState(null);
  const [locations, setLocations] = useState(null);
  const [dropdown, setDropdown] = useState([]);
  // var sitesDropdown = [];

  const redirectDevice = (siteId, locationId) => {
    siteId && locationId && history.push(`/inventory/${siteId}/${locationId}`);
  };

  useEffect(() => {
    if (!state.searchData.length) {
      dispatch({ type: "SET_SEARCH_DATA", payload: [] });
      dispatch({ type: "SET_LOADING", payload: "netboxsitedevices" });
      axios
        .get("/api/netboxsites")
        .then((res) => {
          dispatch({
            type: "SET_SEARCH_DATA",
            payload: res.data.result.results,
          });
          setSites(res.data.result.results);
          // formDropdown(res.data.result.results);
          dispatch({ type: "SET_LOADING", payload: false });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: "SET_LOADING", payload: false });
        });
    }
    if (!state.allLocations.length) {
      dispatch({ type: "SET_ALL_LOCATIONS", payload: [] });
      dispatch({ type: "SET_LOADING", payload: "netboxlocations" });
      axios
        .get("/api/netboxlocations")
        .then((res) => {
          dispatch({
            type: "SET_ALL_LOCATIONS",
            payload: res.data.result.results,
          });
          setLocations(res.data.result.results);
          formDropdown(res.data.result.results);
          dispatch({ type: "SET_LOADING", payload: false });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: "SET_LOADING", payload: false });
        });
    }
  }, []);

  // useEffect(() => {
  const formDropdown = (sites) => {
    var sitesDropdown = [];
    sites.forEach(element => {
      sitesDropdown.push({
        'label': element.name,
        'id': element.id,
        'site': element.site,
      })
    });
    setDropdown(sitesDropdown);
  };
  // }, []);

  useEffect(() => {
    if (state.organisation) {
      setSelection(state.organisation.name);
      // if (state.organisation.site){
      //   setSelection(state.organisation.site.id);
      // } else if (state.organisation.id) {
      //   setSelection(state.organisation.id);
      // }
    }
  }, [state.organisation]);
  const handleClick = (e) => {
    // setSelection(e.target.value);
    dispatch({ type: "SET_ORGANISATION", payload: null });
    dispatch({ type: "SET_UNIFI_SITE", payload: null });
    dispatch({ type: "SET_UNIFI_DATA", payload: null });
    dispatch({ type: "SET_MT_DATA", payload: null });
    dispatch({ type: "SET_UNIFI_SWITCH_DATA", payload: null });
    dispatch({ type: "SET_NETBOX_SITE_DEVICES", payload: null });
    dispatch({ type: "SET_SITE_LOCATIONS", payload: null });

    redirectDevice(e.site.id, e.id);
  };

  const siteSearch = (str) => {
    if (str) {
      const filter = state.searchData.filter((x) =>
        x.name.toLowerCase().includes(str.toLowerCase())
      );
      setSites(filter);
    } else {
      setTimeout(() => {
        setSites(state.searchData);
      }, 500);
    }
  };

  return (
    location.pathname !== "/" && (
      <FormControl style={{ width: 450 }} disabled={!locations ? true : false}>
        <Autocomplete 
          options={locations}
          getOptionLabel={(option) => option.name}
          onChange={(event, value) => handleClick(value)}
          renderInput={(params) => <TextField {...params} label={selection} variant="outlined" />}
        />
      </FormControl>
    )

    // location.pathname !== "/" && (
    //   <FormControl style={{ width: 350 }} disabled={!sites ? true : false}>
    //     <Select
    //       value={!state.organisation ? "" : selection}
    //       onChange={(e) => {
    //         // setSelection(e.target.value);
    //         handleClick(e);
    //       }}
    //     >
    //       {sites &&
    //         sites.map((item, index) => (
    //           <div
    //             key={index}
    //             className="item-hover "
    //             style={{
    //               padding: 7,
    //               cursor: item.id && "pointer",
    //               pointerEvents: !item.id && "none",
    //               opacity: !item.id && 0.3,
    //             }}
    //             value={item.id ? item.id : 0}
    //           >
    //             {item.name} ({item.device_count ? item.device_count : 0} įr.)
    //           </div>
    //         ))}
    //     </Select>
    //   </FormControl>
    // )
  );
}

export default withRouter(NavSitesDropdown);
