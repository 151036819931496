import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import "./chart.css";
import Alert from "../components/AlertMsg";
import CircularProgress from "@material-ui/core/CircularProgress";
import SchoolStats from "../components/SchoolStats";


function Statistics({ props }) {
  const dispatch = useDispatch();
  const [devices, setDevices] = useState(null);
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);
  const state = useSelector((state) => state.data);
  const user = useSelector((state) => state.user.user);
  let { id } = useParams();

  const scrollToRef = (ref) => window.scrollTo(0, 0);

  useEffect(() => {
    if (!state.searchData.length && user.user && user.user.role === "admin") {
      dispatch({ type: "SET_SEARCH_DATA", payload: [] });
      dispatch({ type: "SET_LOADING", payload: "netboxsitedevices" });
      axios
        .get("/api/netboxsites")
        .then((res) => {
          dispatch({
            type: "SET_SEARCH_DATA",
            payload: res.data.result.results,
          });
          dispatch({ type: "SET_LOADING", payload: false });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          dispatch({ type: "SET_LOADING", payload: false });
        });
    }
  }, []);

  return (
    <div>
      {err && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 100,
          }}
        >
          {err && (
            <div
              style={{
                color: "red",
                width: "100%",
                textAlign: "center",
              }}
            >
              <div style={{ marginLeft: "5%", marginRight: "5%" }}>
                <Alert msgType={"error"} txt={err} />
              </div>
            </div>
          )}
        </div>
      )}
      {loading && (
        <div className="centered">
          <CircularProgress
            size={45}
            style={{ marginLeft: 10, marginRight: 10 }}
          />
        </div>
      )}
      <div style={{ height: "100%" }}>
          <>
            <SchoolStats />
          </>
      </div>
    </div>
  );
};
  
export default Statistics;
